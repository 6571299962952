enum QueryKeys {
    Customers = 'customers',
    Projects = 'projects',
    Environments = 'environments',
    Context = 'context',
    Recommendations = 'recommendations',
    RecommendationComments = 'recommendation-comments',
    BusinessGoals = 'business-goals',
    BusinessGoalData = 'business-goal-data',
    BusinessGoalsData = 'business-goals-data',
    FrontEnd = 'frontend',
    Endpoints = 'endpoints',
    RecentBusinessGoalsData = 'recent-business-goals-data',
    WallDashboards = 'wall-dashboards',
    WallDashboardViews = 'wall-dashboard-views',
    EndpointPageSpeedPaths = 'endpoint-page-speed-paths',
    CurrentUser = 'current-user',
    RecommendationVersions = "recommendation-versions",
    RecommendationTemplates = "recommendation-templates",
    AuditInstances = "audit-instances",
    AuditTemplates = "audit-templates",
    RecommendationBusinessGoalLinks = "recommendation-business-goal-links",
    RecommendationBusinessGoalImpacts = "recommendation-business-goal-impacts",
    AccessManagementRules = "access-management-rules",
    BusinessGoalDataResults = "business-goal-data-results",
    Whitelists = 'whitelists',
    WhitelistFirewallRules = "whitelist-firewall-rules",
    WallDashboardFirewallRules = "wall-dashboard-firewall-rules",
    RepositoryLinks = "repository-links",
    Subscriptions = "subscriptions",
    RepositoryInfos = "repository-infos",
    RepositoryBuilds = "repository-builds",
    DeploymentInfos = "deployment-infos",
    BuildDefinitions = "build-definitions",
    ReleaseDefinitions = "release-definitions",
    DeploymentVariables = "deployment-variables",
    FirewallPolicies = "firewall-policies",
    FirewallRuleGroups = "firewall-rule-groups",
    FirewallPolicyDetails = "firewall-policy-details",
    FirewallPolicyExclusions = "firewall-policy-exclusions",
    ProjectDeploymentInfos = "project-deployment-infos",
    ProjectFirewallCombinedData = "project-firewall-combined-data",
    FirewallPolicyPotentialExclusions = "firewall-policy-potential-exclusions",
    FirewallPolicyPotentialExclusionDetails = "firewall-policy-potential-exclusion-details",
    FirewallPotentialExclusionLocks = "firewall-potential-exclusion-locks",
    FirewallActionCount = "firewall-action-count",
    FirewallBlockHistory = "firewall-block-history",
    FirewallIgnoredPotentialExclusions = "firewall-ignored-potential-exclusions",
    UserProjectDisplaySettings = "user-project-display-settings",
    AnonymizationRules = "anonymization-rules",
    FeatureFlags = "feature-flags",
    AllBusinessGoals = "all-business-goals",
    AllProjects = "all-projects",
    FirewallPolicyContext = "firewall-policy-context",
    FirewallPolicyFilters = "firewall-policy-filters",
    DdosOverviews = "ddos-overviews",
    ProjectFirewallDisplaySettings = "project-firewall-display-settings",
    GobelinDevices = "gobelin-devices",
    GobelinDeviceDashboardAssignments = "gobelin-device-dashboard-assignments",
    ProjectOverviews = "project-overviews",
    Logos = "logos",
    DefaultProjectLogo = "default-project-logo",
    FirewallBotDetails = "firewall-bot-details",
    ContactDetails = "contact-details"
}

export default QueryKeys
