// import AuthService, { useAuthService } from '@/auth/AuthService'
import { useMSAuth } from '~/composables/auth/useMSAuth'
import { HttpClient as BaseHttpClient } from '../api/base/BaseHttpClient'

class HttpClient extends BaseHttpClient {
   constructor() {
      super()
      
      const config = useRuntimeConfig()

      this.instance.defaults.baseURL = config.public.apiUrl
   }
}

export default defineNuxtPlugin(nuxtApp => {
   const httpClient = new HttpClient()

   const pinia = usePinia()
   const router = useRouter()
   if (process.client) {
      const authService = useMSAuth()

      httpClient.instance.interceptors.response.use(
         (response) => {
            return response
         },
         (error) => {
            if (error.response && error.response.status === 401) {
               return authService.acquireTokenSilent()
            }

            return Promise.reject(error.response)
         }
      )
   }

   httpClient.instance.interceptors.request.use(config => {
      const authStore = useAuthStore(pinia)

      const { authToken } = storeToRefs(authStore)
      const controller = new AbortController();
      
      if (authToken?.value) {
         config.headers.Authorization = `Bearer ${authToken.value}`
      }
      else {
         controller.abort();
      }

      return { ...config, signal: controller.signal };
   })

   return {
      provide: {
         httpClient: httpClient
      }
   }
 })


